export function initFlashControl() {
  const flashMessages = document.querySelectorAll('#system-message .flash');
  setTimeout(() => {
    flashMessages.forEach(function(flash) {
      flash.classList.add('fade-in');
    });
  }, 2000);
  document.querySelectorAll('.close-flash').forEach(function(element) {
    element.addEventListener('click', function(e) {
      e.preventDefault();
      this.parentNode.remove();
    });
  });
  flashMessages.forEach(function(flash) {
    setTimeout(() => {
      flash.style.opacity = '0';
      setTimeout(() => {
        flash.remove();
      }, 1100); // Slightly longer than the CSS transition duration
    }, 7000);
  });
}